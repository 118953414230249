import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { apiURL } from './api';
import toast from 'react-hot-toast';
import { defaultIdentity } from 'providers/identity';
import { useAuthContext } from 'providers/auth';

export enum IdentityRoutes {
  GET_IDENTITY = '/api/v1/identity',
  UPDATE_IDENTITY = '/api/v1/identity',
  FOLLOW_IDENTITY = '/api/v1/identity/follow',
}

export type CachedNFT = {
  id: number;
  stale: boolean;
  address: string;
  nftID: string;
  nftContractAddress: string;
  imageURL: string;
  createdAt: Date;
}

export type Identity = {
  id: number;
  address: string;
  dateCreated: Date;
  dateUpdated: Date;
  username: string;
  bio: string;
  avatar: string;
  postCount: number;
  likeCount: number;
  followerCount: number;
  followingCount: number;
  isFollowing: boolean;
  nfts: CachedNFT[];
};

const fetchIdentity = async ({ queryKey }: { queryKey: any }): Promise<Identity | null> => {
  try {
    const [_, { address, authToken }] = queryKey;
    const response = await fetch(`${apiURL}${IdentityRoutes.GET_IDENTITY}/${address}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const identityRes = await response.json();

    const nfts: CachedNFT[] = identityRes.nfts ? identityRes.nfts.map((nft: any) => ({
      id: nft.id,
      stale: nft.stale,
      address: nft.address,
      nftID: nft.nft_id,
      nftContractAddress: nft.nft_contract_address,
      imageURL: nft.image_url,
      createdAt: new Date(nft.created_at),
    })) : [];

    const identity: Identity = {
      id: identityRes.id,
      address: identityRes.address,
      bio: identityRes.bio,
      username: identityRes.username || defaultIdentity.username,
      avatar: identityRes.avatar || defaultIdentity.avatar,
      dateCreated: identityRes.date_created,
      dateUpdated: identityRes.date_updated,
      postCount: identityRes.post_count,
      likeCount: identityRes.like_count,
      followerCount: identityRes.follower_count,
      followingCount: identityRes.following_count,
      isFollowing: identityRes.is_following,
      nfts
    };

    return identity;
  } catch (e) {
    console.error(e);
    return null;
  }
};

type FollowIdentityParams = {
  authToken: string;
  follower: string;
  followed: string;
  follow: boolean;
};

const followIdentity = async ({ authToken, follower, followed, follow }: FollowIdentityParams): Promise<{ message: string } | null> => {
  try {
    const response = await fetch(`${apiURL}${IdentityRoutes.FOLLOW_IDENTITY}`, {
      method: follow ? 'POST' : 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        follower: follower,
        followed: followed,
      }),
    });
    const res = await response.json();

    if (!response.ok) {
      console.error(res);
      if ('error' in res) {
        throw new Error(res.error);
      }
      throw new Error('Network response was not ok');
    }
    toast.success(res.message);
    return res;
  } catch (e) {
    console.error(e);
    throw e;
  }
}


type UpdateIdentityParams = {
  authToken: string;
  address: string;
  bio?: string;
  username?: string;
  avatar?: string;
};

const updateIdentity = async ({ authToken, address, username, avatar, bio }: UpdateIdentityParams): Promise<{ id: number } | null> => {
  try {
    const response = await fetch(`${apiURL}${IdentityRoutes.UPDATE_IDENTITY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ address, username, avatar, bio }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (e) {
    console.error(e);
    throw new Error('Error updating identity');
  }
};

export const useIdentity = (address: string | undefined | null) => {
  const { authenticated } = useAuthContext();
  return useQuery({
    queryKey: ['identity', { address, authToken: authenticated.token }],
    queryFn: fetchIdentity,
    enabled: !!address,
  });
}

export const useUpdateIdentity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateIdentity,
    onSuccess: () => {
      toast.success('Identity updated');
      queryClient.invalidateQueries({ queryKey: ['identity'] });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};

export const useFollowIdentity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: followIdentity,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['identity'] });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
}

